import payload_plugin_RQjZDW5ary from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.4.5_vue@3.5.13_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_GnBpQDs1tg from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AG6VO073vr from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_D0Qi9uBil4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9QxLAElArw from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ne5BwD2gbJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BaOwj1vfO7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2yqA8Ej2w0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YNmKwGKvRH from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.4.5_vue@3.5.13_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_ChT3j6Yw7G from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredi_gogcaanial5aztrizcobxy7gfm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_dNXZwPMJNd from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.43.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.29.0_@opentelemetry+api@1._qndepplz75ffs3aykwybff62mm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import _01_plugin_YX41sy7Jvk from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.38.5_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_terser@5.37.0_/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import _02_meta_ktUJQIGyGH from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.38.5_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_terser@5.37.0_/node_modules/nuxt-i18n-micro/dist/runtime/plugins/02.meta.js";
import _03_define_iPPwmYmU9T from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.38.5_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_terser@5.37.0_/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  payload_plugin_RQjZDW5ary,
  revive_payload_client_GnBpQDs1tg,
  unhead_AG6VO073vr,
  router_D0Qi9uBil4,
  payload_client_9QxLAElArw,
  navigation_repaint_client_ne5BwD2gbJ,
  check_outdated_build_client_BaOwj1vfO7,
  chunk_reload_client_2yqA8Ej2w0,
  plugin_vue3_YNmKwGKvRH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ChT3j6Yw7G,
  sentry_client_dNXZwPMJNd,
  sentry_client_config_o34nk2sJbg,
  _01_plugin_YX41sy7Jvk,
  _02_meta_ktUJQIGyGH,
  _03_define_iPPwmYmU9T
]