import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "localized-index",
    path: "/:locale(cs)",
    component: () => import("/vercel/path0/pages/index.vue")
  }
]