<template>
  <div class="pt-[82px] lg:pt-[106px]">
    <div class="flex min-h-[500px] items-center justify-center">
      <div class="px-2 py-3 text-center font-semibold">
        <h2 class="mb-8 text-[50px] font-bold leading-none md:text-[80px]">
          404
        </h2>
        <h4 class="mb-5 text-xl font-semibold text-primary sm:text-5xl">
          Ooops!
        </h4>
        <p class="text-base">
          The page you requested was not found!
        </p>
        <a href="/" class="btn mx-auto mt-10 w-max">Back To Home</a>
      </div>
    </div>
  </div>
</template>
